import React, { useEffect, useRef, useState } from 'react';

import { PlusOutlined, DeleteOutlined, SearchOutlined } from '@ant-design/icons';
import { Drawer, Button, Typography, Row, Col, Input, Space, Table, Form, Tag, Select } from 'antd';
import moment from 'moment';

import Head from '../../components/Head';
import MainLayout from '../../components/MainLayout';
import { useAuth } from '../../hooks';
import userUser from '../../hooks/useUser';

export const AdminUsersPage = () => {
    const [addUserDrawerVisibility, setAddUserDrawerVisibility] = useState(false);
    const [inputSearchString, setInputSearchString] = useState('');
    const { adminCheck } = useAuth();
    const { users, refreshUsers, createUser, userLoading, deleteUser } = userUser();
    const [addUserForm] = Form.useForm();

    useEffect(() => {
        refreshUsers();
        adminCheck();
    }, []);

    const filterSearch = (users: Users.User[]) => {
        if (!inputSearchString) {
            return users;
        } else
            return users.filter(user => {
                return (
                    user.emailId?.toLocaleLowerCase()?.includes(inputSearchString) ||
                    user.firstName?.toLocaleLowerCase()?.includes(inputSearchString) ||
                    user.lastName?.toLocaleLowerCase()?.includes(inputSearchString) ||
                    user.roles?.join('')?.toLocaleLowerCase()?.includes(inputSearchString)
                );
            });
    };

    return (
        <MainLayout>
            <>
                <Head title="Users" />
                <Typography.Title level={2}>Users</Typography.Title>
                <Typography.Paragraph>Manage AutoAPA users</Typography.Paragraph>
                <Row style={{ marginBottom: '1.5rem' }}>
                    <Col span={12}>
                        <Input
                            placeholder="Filter users"
                            onChange={e => setInputSearchString(e.target.value)}
                            prefix={<SearchOutlined />}
                        />
                    </Col>
                    <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                        <Space>
                            <Button
                                icon={<PlusOutlined />}
                                type="primary"
                                onClick={() => setAddUserDrawerVisibility(true)}
                            >
                                Add User
                            </Button>
                        </Space>
                    </Col>
                </Row>
                <Row>
                    <Table
                        pagination={false}
                        style={{ width: '100%' }}
                        dataSource={filterSearch(users)}
                        rowKey={(record: Users.User) => record._id!}
                        columns={[
                            {
                                title: 'Email',
                                dataIndex: 'emailId',
                                key: 'emailId',
                            },
                            {
                                title: 'Name',
                                // dataIndex: 'age',
                                key: 'name',
                                render: (_, user) => `${user.firstName || ''} ${user.lastName || ''}`,
                            },
                            {
                                title: 'Created',
                                dataIndex: 'createdAt',
                                key: 'createdAt',
                                render: (createdAt: string) => moment(createdAt).fromNow(),
                            },
                            {
                                title: 'Role',
                                dataIndex: 'roles',
                                key: 'roles',
                                render: (roles: string) =>
                                    roles[0] === 'student' ? (
                                        <Tag color="blue">Student</Tag>
                                    ) : (
                                        <Tag color="red">Admin</Tag>
                                    ),
                            },
                            {
                                key: 'actions',
                                width: '10%',
                                align: 'center',
                                dataIndex: '_id',
                                render: _id => (
                                    <Button
                                        size="small"
                                        icon={<DeleteOutlined />}
                                        danger
                                        loading={userLoading}
                                        onClick={() => deleteUser(_id)}
                                    />
                                ),
                            },
                        ]}
                    />
                </Row>
                <Drawer
                    visible={addUserDrawerVisibility}
                    onClose={() => setAddUserDrawerVisibility(false)}
                    destroyOnClose
                    title="New User"
                    footer={
                        <Space>
                            <Button onClick={() => setAddUserDrawerVisibility(false)}>Cancel</Button>
                            <Button type="primary" loading={userLoading} onClick={() => addUserForm.submit()}>
                                Continue
                            </Button>
                        </Space>
                    }
                    footerStyle={{ textAlign: 'right' }}
                >
                    <Form labelCol={{ span: 24 }} onFinish={createUser} form={addUserForm}>
                        <Form.Item
                            name="emailId"
                            label="Email ID"
                            rules={[{ required: true, message: 'Please input email!' }]}
                        >
                            <Input type="email" />
                        </Form.Item>
                        <Form.Item
                            name="firstName"
                            label="First Name"
                            rules={[{ required: true, message: 'Please input first name!' }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="lastName"
                            label="Last Name"
                            rules={[{ required: true, message: 'Please input last name!' }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            label="Password"
                            rules={[{ required: true, message: 'Please input password!' }]}
                        >
                            <Input type="password" />
                        </Form.Item>
                        <Form.Item
                            name="role"
                            label="Role"
                            rules={[{ required: true, message: 'Please select a role!' }]}
                        >
                            <Select>
                                <Select.Option value="admin">Admin</Select.Option>
                                <Select.Option value="student">Student</Select.Option>
                            </Select>
                        </Form.Item>
                    </Form>
                </Drawer>
            </>
        </MainLayout>
    );
};

export default AdminUsersPage;
